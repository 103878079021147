import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import MainPage from './components/MainPage';
import CV from './components/CV';
import Portfolio from './components/Portfolio';
import Products from './components/Products';
import Footer from "./components/Footer";
import GlobePage from "./components/GlobePage";
import './App.css';

function App() {
    return (
        <Router>
            <Navbar bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand as={Link} to="/">Lourenco Matos</Navbar.Brand>
                    <Nav className="ml-auto">
                        <Nav.Link as={Link} to="/cv">Curriculum Vitae</Nav.Link>
                        <Nav.Link as={Link} to="/portfolio">Portfolio</Nav.Link>
                        <Nav.Link as={Link} to="/products">Products</Nav.Link>
                        <Nav.Link as={Link} to="/globe">World Exploration</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>

            <Container className="mt-3">
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/cv" element={<CV />} />
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/globe" element={<GlobePage />} />
                </Routes>
            </Container>

            <Footer />
        </Router>
    );
}


export default App;
