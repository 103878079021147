import React from 'react';

function Products() {
    return (
        <div>
            <h2>No products yet.</h2>
        </div>
    );
}

export default Products;
