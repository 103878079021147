import React, { useEffect, useState } from 'react';
import Globe from 'globe.gl';
import { simplify, featureCollection } from '@turf/turf';

function GlobePage() {
    const [globeData, setGlobeData] = useState(null);

    useEffect(() => {
        async function loadAndSetGlobeData() {
            try {
                console.log('Fetching GeoJSON data...');
                const response = await fetch('/out.geojson');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                let countries = await response.json();

                // Simplify the GeoJSON to reduce the size and number of points
                //countries = simplify(featureCollection(countries.features), { tolerance: 0.01, highQuality: false });

                setGlobeData(countries);
            } catch (error) {
                console.error('Error loading the GeoJSON data:', error);
            }
        }

        loadAndSetGlobeData();
    }, []);

    useEffect(() => {
        if (globeData) {
            console.log('Initializing the globe...');
            // Initialize the globe
            const myGlobe = Globe()(document.getElementById('globeViz'))
                .globeImageUrl('//unpkg.com/three-globe/example/img/earth-blue-marble.jpg')
                // Remove the bump map for better performance
                // .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png');


            async function getVisitedCountries() {
                try {
                    const response = await fetch('/helpers/country_visits.json');
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const { visited } = await response.json();

                    // Convert the visited object to an array of ISO codes
                    const visitedCountries = Object.values(visited);
                    
                    console.log(visitedCountries)

                    // Define a function to check if a country is visited
                    const isVisited = (country) => visitedCountries.includes(country.properties.SOV_A3);

                    myGlobe
                        .polygonsData(globeData.features)
                        .polygonCapColor((country) => isVisited(country) ? 'rgb(255,221,238)' : 'rgba(0, 0, 0, 0)')
                        .polygonAltitude(0.01)
                        .polygonSideColor(() => 'rgba(0, 100, 0, 0.15)')
                        .polygonStrokeColor(() => '#111');
                } catch (error) {
                    console.error('Error loading visited countries:', error);
                }
            }

            getVisitedCountries();
        }
    }, [globeData]);

    return (
        <div id='globeViz'>
            {!globeData && <div>Loading...</div>}
        </div>
    );
}

export default GlobePage;
