import React from "react";
import { Container, Row, Col } from 'react-bootstrap';

function CV() {
    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col xs={12} md={20}>
                    <h2>About me</h2>
                    <p>
                        I am a dedicated individual with a strong interest in learning all sorts of things. Lately i've been focused
                        on cybersecurity, artificial intelligence, machine learning and research.
                        I am driven to learn and evolve on a daily basis.
                        I possess a high level of proficiency in Python and C, and I am well-versed in technologies such as Unix and Git.
                    </p>

                    <h2>Education</h2>
                    <p>
                        Instituto Superior Técnico – University of Lisbon - BSc in Computer Science and Engineering
                    </p>

                    <h2>Experience</h2>
                    <ul>
                        <li>Student Research Engineer at Instituto Superior Técnico</li>
                        <li>Students Mentor at Instituto Superior Técnico</li>
                        <li>Active contributor to open-source projects</li>
                        <li>CTF's, Coding competitions, etc.</li>
                    </ul>

                    <h2>Projects</h2>
                    <ul>
                        <li>Machine Learning Trading bot (Python)</li>
                        <li>AI Engine - Bimaru Solver (Python)</li>
                        <li>Filesystem and Message Broker (C)</li>
                        <li>Mobile Network (Java)</li>
                        <li>Full Stack Web Application to manage company's database (Python, Js, Html, Css)</li>
                        <li>Along with many more small projects, etc.</li>

                    </ul>

                    <h2>Skills</h2>
                    <ul>
                        <li>Problem Solving</li>
                        <li>Algorithmic and efficiency-oriented thinking</li>
                        <li>Multiple programming languages</li>
                        <li>Unix and operating systems</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}

export default CV;
