import React from 'react';

function MainPage() {
    return (
        <div>
            <br/>
            <h1>Hey!</h1>
            <br/>
            <h2>My name is Lourenco.</h2>
            <br/>
            <h3>And this is my website.</h3>
        </div>
    );
}

export default MainPage;

