import React from "react";
import { Container } from 'react-bootstrap';
import { FaGithub, FaEnvelope } from "react-icons/fa";

function Footer() {
    return (
        <div className="d-flex justify-content-center mb-3 bg-transparent footer">
            <Container className="d-flex justify-content-center">
                <a href="https://github.com/Lourencom" className="text-dark me-4">
                    <FaGithub size={32} />
                </a>
                <a href="mailto:me@lourencomatos.com" className="text-dark">
                    <FaEnvelope size={32} />
                </a>
            </Container>
        </div>
    );
}

export default Footer;
